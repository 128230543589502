<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          light
        >
          <v-btn
            icon
            light
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.edit') }} {{ $t('app.roles.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            rounded
            depressed
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-list
          three-line
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.roles.roleName') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="item.name"
                  :placeholder="$t('app.roles.roleName')"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  v-model="item.description"
                  :placeholder="$t('app.headers.description')"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          flat
        >
          <v-subheader>
            {{ $t('app.headers.permissions') }}
          </v-subheader>
          <v-list-item-group
            v-model="permissions"
            multiple
          >
            <template
              v-for="(module, index) in modules"
            >
              <v-list-item
                :key="`module-${index}`"
              >
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="active"
                      color="primary"
                      @click="toggle"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ module.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ module.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <template
                v-if="module.children"
              >
                <v-list-item
                  v-for="(submodule, subindex) in module.children"
                  :key="`submodule-${index}-${subindex}`"
                >
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="active"
                        color="primary"
                        @click="toggle"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ submodule.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ submodule.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'RolesEdit',
    data () {
      return {
        dialog: false,
        item: {
          permissions: []
        },
        permissions: [],
        flatModules: []
      }
    },
    computed: {
      ...mapState(['modules']),
    },
    created () {
      EventBus.$on('roles-edit', (item) => {
        this.item = item

        let main = []

        for (let module of this.modules) {
          main.push(module.to)

          if (module.hasOwnProperty('children') && module.children) {
            for (let child of module.children) {
              main.push(child.to)
            }
          }
        }

        this.flatModules = main

        this.permissions = this.item.permissions.map(permission => main.indexOf(permission.route))

        this.dialog = true
      })
    },
    async mounted () {
    },
    methods: {
      async saveItem () {
        this.toggleLoader()

        let permissions = this.flatModules.filter((module, index) => {
          if (this.permissions.includes(index)) {
            return true
          }
        })

        this.item.permissions = permissions

        await this.$http.post(route('v1/role/' + this.item.id), this.item)
          .then(response => {
            if (response.body.code === 200) {
              this.processSuccess(response.body)
              this.dialog = false
              EventBus.$emit('reload-items')
            } else {
              this.processError(response.body)
            }
          }, error => {
            this.processError(error)
          })

        this.dialog = false
      },
    },
  }
</script>

<style scoped>

</style>
